import React from "react";

const sectionbreak = ({breakName}) => {
  return (
    <h3 class="flex items-center mt-3 w-full">
      <span class="flex-grow bg-gray-200 rounded h-1"></span>
      <span class="mx-3 text-lg font-medium">{breakName}</span>
      <span class="flex-grow bg-gray-200 rounded h-1"></span>
    </h3>
  );
};


export default sectionbreak;