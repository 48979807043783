import React from "react";
import { Link } from "react-router-dom";
import data from "../data/contacts.json";
import {
  LinkedinOutlined,
  MailOutlined,
  GithubOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";

function Footer() {
  return (
    <div className="  ">
      <div className="text-center  p-2 bg-base-200 text-black footer flex flex-col w-full  ">
        <div className="flex flex-col">
          <h1 className="text-black justify-self-center text-xl dark:text-white ">
            Contact me:
          </h1>
        </div>

        <div className="flex  w-full ">
          <ul className="flex flex-row  w-full justify-center ">
            <li className="w-1/4">
              <a href={data[0].linkedin} className="text-white ">
                <LinkedinOutlined style={{ fontSize: "32px", color: "#08c" }} />
              </a>
            </li>
            <li className=" w-1/4 ">
              <a href={`mailto:${data[0].email}`} className="text-white">
                <MailOutlined style={{ fontSize: "32px", color: "#08c" }} />
              </a>
            </li>
            <li className=" w-1/4">
              <a href={data[0].github} className="text-white">
                <GithubOutlined style={{ fontSize: "32px", color: "#08c" }} />
              </a>
            </li>
            <li className="w-1/4">
              <a href={data[0].resume}>
                <FilePdfOutlined style={{ fontSize: "32px", color: "#08c" }} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
