import React from "react";
import ProfileImage from "./image/profileImage";
import { Link } from "react-router-dom";
import contacts from "../data/contacts.json";
const About = () => {
  return (
    <section className="grid  md:grid-cols-2  items-center justify-center">
      <ProfileImage />
      <div className="flex flex-col">
        <div className="flex flex-col md:text-left text-center ">
          <h1 className="text-4xl font-bold">Sam Fung</h1>
          <p className="text-xl font-semibold">Programmer</p>
          <p className="text-lg font-normal">
            Email:{" "}
            <a className="link" href={`mailto:${contacts[0].email}`}>
              samfung61@gmail.com
            </a>
          </p>
          <a className="link link-primary" href={contacts[0].resume}>
            Download Resume
          </a>
        </div>
        <div className="mt-3">
          <h3 className="text-sm ">About Me:</h3>
          <p className="text-xs mt-3">
            Hi, my name is sam. I got the BSc (Hons) in Computing degree at The
            Hong Kong Polytechnic University and Higher Diploma in Software
            Engineering at Hong Kong Institute of Vocational Education .{" "}
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
