import React from "react";
import projectData from "../data/projects.json";

const PastProject = () => {
  return (
    <section id="projects" >
        <div className="flex flex-col mb-10 gap-5">
        {Object.keys(projectData).map((key) => (
          <div className="card mt-3 bg-base-200 shadow-xl" key={key}>
            <div className="card-body">
              <div className="card-title">
                {key}
              </div>
              <p>{projectData[key].description}</p>
              <p><strong>Languages:</strong> {projectData[key].languages.join(", ")}</p>
              <p><strong>Highlights:</strong> 
              <div  className="flex mt-3 flex-row gap-4 flex-wrap">
              {projectData[key].highlights.map((highlight) => (
                <div className="badge bg-slate-400 dark:bg-slate-600">
                  {highlight}
                </div>
                
              ))}
              </div>
          
               </p>
            </div>
          </div>
        ))}
        </div>
    </section>
  );
};

export default PastProject;
