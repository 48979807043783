import React from 'react';
import About from '../components/aboutme.jsx' ;
import Education from '../components/education.jsx';
import SectionBreak from '../components/sectionBreak.jsx';
import WorkExp from '../components/workexp.jsx';
const App: React.FC = () => {
  return (
    <div className="container flex-grow mx-auto  w-full justify-self-center  py-6 px-12">
      <About/>
      <SectionBreak breakName="Education" />
      <Education/>
      <SectionBreak breakName="Work Experience" />
      <WorkExp/>
    </div>
    
  );
};

export default App;