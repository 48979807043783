import React from "react";
import workData from "../data/work.json";
const WorkExp = () => {
  return (
    <section id="work">

  
    <div className="mt-3">
      {workData.map((item) => (
        <div className="flex flex-col py-3 " key={item.id}>
          <h1 className="text-2xl font-bold">{item.company}</h1>
          <div className="flex flex-col md:flex-row  justify-between">
            <p className="text-lg font-semibold">{item.title}</p>
            <p className="text-lg font-normal  ">{item.date}</p>
          </div>
        <div className="ml-4">

   
          <ul className="list-disc list-inside">
            {item.description.map((job) => (
              <li key={job}>{job}</li>
            ))}
          </ul>
          </div>
        </div>
      ))}
    </div>
    </section>
  );
};

export default WorkExp;
