import React, { useState, Suspense, lazy } from "react";

import { useNavigate } from "react-router-dom";
import navData from "../data/nav.json"; // Updated path
import { BookOutlined, MenuOutlined } from "@ant-design/icons";
import "../styles/Nav.css"; // Updated path

const iconMap = {
  HomeOutlined: lazy(() =>
    import("@ant-design/icons").then((module) => ({
      default: module.HomeOutlined,
    }))
  ),
  ProjectOutlined: lazy(() =>
    import("@ant-design/icons").then((module) => ({
      default: module.ProjectOutlined,
    }))
  ),
  BookOutlined: lazy(() =>
    import("@ant-design/icons").then((module) => ({
      default: module.BookOutlined,
    }))
  ),
  TableOutlined: lazy(() =>
    import("@ant-design/icons").then((module) => ({
      default: module.TableOutlined,
    }))
  ),
};

const Nav = () => {
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState(null);

  const onClick = (slug) => {
   // console.log(slug);
    setSelectedKey(slug);
    navigate(slug);

    if (slug.includes("#")) {
      const part = slug.substring(2);
      console.log(part);
      let section = document.getElementById(part);
      console.log(section);
      if (section) {
        section.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }
    
  };

  return (
    <div className="navbar sticky z-30 top-0 bg-base-100">
      <div className="flex-1">
        <a href="/" className="btn btn-ghost text-xl sm:text-sm">
          Sam Fung
        </a>
      </div>
      <div className="flex-none ">
        <ul className="menu menu-horizontal px-1 sm:px-0 hidden md:flex">
          {navData.nav.map((item) => {
            const IconComponent = iconMap[item.icon.replace(/<| \/>/g, "")];
            return (
              <li key={item.slug}>
                <a
                  className={`btn ${
                    selectedKey === item.key ? "btn-primary" : "btn-ghost"
                  }`}
                  onClick={() => onClick(item.slug)}
                >
                  <Suspense
                    fallback={<div>Loading...</div>}
                    className="hidden lg:block"
                  >
                    <IconComponent className="hidden md:block" />
                  </Suspense>

                  <p className="sm:text-sm">{item.name}</p>
                </a>
              </li>
            );
          })}
        </ul>
        <ul className="menu menu-horizontal pr-12 flex md:hidden">
          <li className=""></li>
          <li>
            <details>
              <summary className=" px-3">
                <MenuOutlined />
              </summary>
              <ul className="bg-base-100 rounded-t-none p-2">
                {navData.nav.map((item) => {
                  const IconComponent =
                    iconMap[item.icon.replace(/<| \/>/g, "")];
                  return (
                    <li key={item.slug}>
                      <a
                        className={`btn ${
                          selectedKey === item.key ? "btn-primary" : "btn-ghost"
                        }`}
                        onClick={() => onClick(item.slug)}
                      >
                        <Suspense
                          fallback={<div>Loading...</div>}
                          className="hidden lg:block"
                        >
                          <IconComponent className="hidden md:block" />
                        </Suspense>

                        <p className="sm:text-sm">{item.name}</p>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </details>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Nav;
