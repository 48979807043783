import React from "react";

const ProfileImage  = () => {
    return (
       
        <img
            
            src="images/profolio.JPG"
            alt="profolio"
            className="rounded-full mx-auto w-64 h-64 object-cover"
             loading="lazy"
        />
        
    );
    };

export default ProfileImage;