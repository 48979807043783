import React from 'react';
import ReactDOM from 'react-dom';
import { Analytics } from "@vercel/analytics/react"
import { BrowserRouter as Router, Route, Routes ,Navigate} from 'react-router-dom';
import App from './page/index.tsx';
import About from './page/projects.tsx';
import Nav from './components/Nav.jsx';
import Footer from './components/footer.jsx';

import './styles/input.css';
import Projects from './page/projects.tsx';
const Root = () => (
  
  <div className="main-content  flex flex-col justify-between">
    <Analytics/>
    
    <Router>
      <Nav className="nav-container " />
      <div className=''>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects/>} />
      </Routes>
      </div>
    </Router>


    <Footer />
  </div>

  
);

ReactDOM.render(<Root />, document.getElementById('root'));