import React from 'react';
import PastProject from '../components/pastProject.jsx';
import SectionBreak from '../components/sectionBreak.jsx';
const Projects: React.FC = () => {
 
  


  return (
    
    <div className="flex-grow mx-auto  w-full justify-self-center  py-6 px-12">
    <SectionBreak breakName="Past Projects" />

      <PastProject/>
    </div>

  );
};

export default Projects;