import React from "react";

import education from "../data/education.json";
const Education = () => {
  var id = 0;
  return (
    <section className="pt-3 flex flex-col " id="education">
      {education.map((item) => (
        <div className="flex flex-col py-3 " key={item.id}>
          <h1 className="text-2xl font-bold">{item.school}</h1>
          <div className="flex flex-col md:flex-row  justify-between">
            <p className="text-lg font-semibold">{item.degree}</p>
            <p className="text-lg font-normal  ">
              {item.start} - {item.end}
            </p>
          </div>
          <div tabIndex={++id} className=" mt-3 collapse collapse-arrow border bg-base-200">
            <div class="collapse-title text-xl font-medium">
              Course Hightlight
            </div>
            <div class="collapse-content">
              <table className="table overflow-scroll  table-zebra">
                <thead>
                  <tr>
                    <th className="hidden md:block">Course Code </th>
                    <th>Course Name </th>
                    <th>Grade</th>
                  </tr>
                </thead>
                <tbody className=" even:bg-slate-400 dark:even:bg-gray-600">
                {Object.entries(item.course).map(([key, course]) => (
              <tr key={key}>
                <td className="hidden md:block">{key}</td>
                <td>{course.name}</td>
                <td>{course.grade}</td>
              </tr>
            ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
      ))}
    </section>
  );
};

export default Education;
